import Chips from '../../Components/Chips'
import IntroEnglish from '../../Components/english/IntroEnglish'
import ProjectsSectionEnglish from '../../Components/english/ProjectsSectionEnglish'
import AboutMeEnglish from '../../Components/english/AboutMeEnglish'
import HeaderEnglish from '../../Components/english/HeaderEnglish'

const HomeEnglish = () => (
  <div className="container">
    <HeaderEnglish />
    <IntroEnglish />
    <Chips />
    <ProjectsSectionEnglish />
    <AboutMeEnglish />
  </div>
)

export default HomeEnglish
